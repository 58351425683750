<template>
  <LiefengContent>
    <template v-slot:title>积分管理及排行</template>
    <template v-slot:toolsbarRight>
      <Button type="primary" @click="clearRow(selectArr)">批量清零</Button>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :tableData="tableData"
        :talbeColumns="talbeColumns"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100]"
        :curPage="curPage"
        @hadlePageSize="hadlePageSize"
        @tableSelect="tableSelect"
      ></LiefengTable>
      <!-- 消费弹窗 -->
      <LiefengModal
        title="消费"
        :value="spendStatus"
        width="500px"
        height="190px"
        @input="spendStatusFn"
      >
        <template v-slot:contentarea>
          <Form :model="spendData" :rules="spendValidator" :label-colon="true" :label-width="90">
            <FormItem label="消费类型" prop="type">
              <Select v-model="spendData.type" style="width: 200px">
                <Option
                  v-for="item in spendType"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="积分数" prop="integral">
                <Input :maxlength="20" v-model.trim="spendData.integral" style="width: 380px"></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
           <Button type="info" @click="spendStatusFn(false)" style="margin-right: 10px">取消</Button>
           <Button type="primary" @click="spendSave">确定</Button>
        </template>
      </LiefengModal>
      <!-- 增加弹窗 -->
      <LiefengModal
        title="增加"
        :value="addStatus"
        width="500px"
        height="250px"
        @input="addStatusFn"
      >
        <template v-slot:contentarea>
          <Form :model="addData" :rules="addValidator" :label-colon="true" :label-width="90">
            <FormItem label="增加类型" prop="type">
              <Select v-model="addData.type" style="width: 200px">
                <Option
                  v-for="item in addType"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="积分数" prop="integral">
                <Input :maxlength="20" v-model.trim="addData.integral" style="width: 380px"></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
           <Button type="info"  @click="addStatusFn(false)" style="margin-right: 10px">取消</Button>
           <Button type="primary" @click="addSave">确定</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/statisticsintegral')
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      tableData: [],
      talbeColumns: [
        {
          type: "selection",
          width: 100,
          align: "center",
        },
        {
          title: "排行",
          key: "sort",
          align: "center",
          width: 160,
        },
        {
          title: "姓名",
          key: "name",
          align: "center",
          width: 160,
        },
        {
          title: "手机号",
          key: "phone",
          align: "center",
          width: 180,
        },
        {
          title: "积分总数",
          key: "totalPoints",
          align: "center",
          width: 160,
        },
        {
          title: "比昨日",
          key: "yesterday",
          align: "center",
          width: 160,
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  color:
                    params.row.yesterday.indexOf("+") != "-1"
                      ? "#00CC00"
                      : "#FF0000",
                },
              },
              params.row.yesterday
            );
          },
        },
        {
          title: "注册时间",
          key: "registrationTime",
          align: "center",
          minWidth: 180,
        },
        {
          title: "操作",
          align: "center",
          width: 200,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "success",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                        this.spendId = params.row.id,
                        this.spendStatusFn(true)
                    },
                  },
                },
                "消费"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "info",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                         this.addId = params.row.id,
                        this.addStatusFn(true)
                    },
                  },
                },
                "增加"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.clearRow(params.row.id);
                    },
                  },
                },
                "清零"
              ),
            ]);
          },
        },
      ],
      loading: false,
      pageSize: 20,
      total: 0,
      curPage: 1,
      //表格选中
      selectArr: [],
      //消费
      spendId: '',
      spendStatus: false,
      spendData: {
          type: '',
          integral: '',
      },
      spendValidator: {
        type: [
          {required: true,message: "请输入消费类型",trigger: "blur"}
        ],
        integral: [
          {required: true,message: "请输入积分数",trigger: "blur"}
        ]
      },
      spendType: [
          {
          label: '福利兑换',
          value: '1'
        },
          {
          label: '其他',
          value: '2'
        },
      ],
      //增加
       addId: '',
      addStatus: false,
      addData: {
          type: '',
          integral: '',
      },
      addValidator: {
        type: [
          {required: true,message: "请输入增加类型",trigger: "blur"}
        ],
        integral: [
          {required: true,message: "请输入积分数",trigger: "blur"}
        ]
      },
      addType: [
          {
          label: '退回',
          value: '1'
        },
          {
          label: '邀请新用户',
          value: '2'
        },
          {
          label: '签到',
          value: '3'
        },
          {
          label: '其他',
          value: '4'
        },
      ],
    };
  },
  methods: {
      //新增确定
      addSave() {
          this.$post('',{
              id: this.addId,
              type: this.addData.type,
              integral: this.addData.integral
          }).then(res => {
              if(res.code == 200) {

              }
          })
      },
    //新增弹窗
    addStatusFn(status) {
      this.addStatus = status;
    },
      //消费确定
      spendSave() {
          this.$post('',{
              id: this.spendId,
              type: this.spendData.type,
              integral: this.spendData.integral
          }).then(res => {
              if(res.code == 200) {

              }
          })
      },
    //消费弹窗
    spendStatusFn(status) {
      this.spendStatus = status;
    },
    //清零
    clearRow(id) {
      this.$Modal.confirm({
        title: "删除确定",
        content: "您正在对信息进行清零，是否继续？",
        onOk: () => {
          this.$post("/testjson/statistics/integraldata.json", {
            id: id.join(","),
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "操作成功",
                });
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              this.$Message.error({
                background: true,
                content: "操作失败",
              });
            });
        },
      });
    },
    //表格多选
    tableSelect(row) {
      this.selectArr = [];
      row.map((item, index) => {
        this.selectArr.push(item.id);
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/testjson/statistics/integraldata.json", {
        pageSize: obj.pageSize,
        curPage: obj.page,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item, index) => {
              if(item.registrationTime) {
                item.registrationTime = this.$core.formatDate(
                new Date(item.registrationTime),
                "yyyy-MM-dd hh:mm:ss"
              );
              }
            });
            this.tableData = res.dataList;
            this.pageSize = res.pageSize;
            this.total = res.total;
            this.curPage = res.curPage;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$Message.error({
            background: true,
            content: "获取数据失败",
          });
        });
    },
  },
  created() {
    this.hadlePageSize({
      pageSize: this.pageSize,
      page: this.curPage,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
</style>